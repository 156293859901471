import React, { useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';

/**
 * Converts a hex color to rgba format
 */
const hexToRgba = (initialHex, opacity = 0.5) => {
  const hex = initialHex.replace(/^#/, '');
  const normalizedHex =
    hex.length === 3
      ? hex
          .split('')
          .map((char) => char + char)
          .join('')
      : hex;

  const r = parseInt(normalizedHex.substring(0, 2), 16);
  const g = parseInt(normalizedHex.substring(2, 4), 16);
  const b = parseInt(normalizedHex.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

/**
 * Finds the optimal font size and wraps text to fit within circle constraints
 */
const wrapText = (
  text,
  maxWidth,
  lineHeight,
  element,
  fontSize,
  circleRadius
) => {
  const words = text.split(/,\s*/);
  const maxAllowedHeight = circleRadius * 1.2;

  const tryWrapWithFontSize = (size) => {
    element.selectAll('text').remove();

    let line = [];
    let lineNumber = 0;
    let maxLineWidth = 0;

    const createTspan = (dy = 0) =>
      element
        .append('text')
        .attr('text-anchor', 'middle')
        .attr('fill', '#1a1a1a')
        .attr('font-size', `${size}px`)
        .attr('dy', dy);

    let tspan = createTspan();

    words.forEach((word) => {
      line.push(word);
      tspan.text(line.join(', '));

      if (tspan.node().getComputedTextLength() > maxWidth) {
        line.pop();
        tspan.text(line.join(', '));
        maxLineWidth = Math.max(
          maxLineWidth,
          tspan.node().getComputedTextLength()
        );

        lineNumber += 1;
        line = [word];

        tspan = createTspan(lineHeight * lineNumber).text(word);
      }
    });

    if (line.length > 0 && line[0] !== '') {
      const finalLine = createTspan(lineHeight * lineNumber).text(
        line.join(', ')
      );
      maxLineWidth = Math.max(
        maxLineWidth,
        finalLine.node().getComputedTextLength()
      );
      lineNumber += 1;
    }

    const totalHeight = lineNumber * lineHeight;
    return totalHeight <= maxAllowedHeight;
  };

  let minSize = 10;
  let maxSize = fontSize;
  let optimalSize = minSize;

  while (minSize <= maxSize) {
    const midSize = Math.floor((minSize + maxSize) / 2);
    if (tryWrapWithFontSize(midSize)) {
      optimalSize = midSize;
      minSize = midSize + 1;
    } else {
      maxSize = midSize - 1;
    }
  }

  tryWrapWithFontSize(optimalSize);
};

/**
 * Calculates the position for each circle in the Venn diagram
 */
const calculateCirclePosition = (index, width, height, circleRadius) => {
  const positions = [
    { cx: width / 2 - circleRadius, cy: height / 2 - circleRadius / 0.75 },
    { cx: width / 2 + circleRadius, cy: height / 2 - circleRadius / 0.75 },
    { cx: width / 2, cy: height / 2 + circleRadius / 4 },
    { cx: width / 2 - circleRadius, cy: height / 2 + circleRadius / 0.55 },
    { cx: width / 2 + circleRadius, cy: height / 2 + circleRadius / 0.55 },
  ];

  return index < positions.length
    ? positions[index]
    : { cx: width / 2, cy: height / 2 };
};

/**
 * Draws a circle with title and keywords text
 */
const drawCircleWithText = (svg, circle, circleRadius, fontSize) => {
  svg
    .append('circle')
    .attr('cx', circle.cx)
    .attr('cy', circle.cy)
    .attr('r', circleRadius)
    .attr('fill', circle.color);

  const textGroup = svg
    .append('g')
    .attr(
      'transform',
      `translate(${circle.cx}, ${circle.cy - circleRadius / 2.3})`
    );

  textGroup
    .append('text')
    .attr('text-anchor', 'middle')
    .attr('fill', '#1a1a1a')
    .attr('font-size', `${fontSize}px`)
    .attr('font-weight', 'bold')
    .text(circle.title);

  const keywordsGroup = svg
    .append('g')
    .attr(
      'transform',
      `translate(${circle.cx}, ${circle.cy - circleRadius / 3.5})`
    );

  wrapText(
    circle.keywords,
    circleRadius * 1.3,
    fontSize,
    keywordsGroup,
    fontSize,
    circleRadius
  );
};

/**
 * Venn Diagram Component
 */
const VennDiagramComponent = ({ circles = [] }) => {
  const containerRef = useRef(null);
  const svgRef = useRef(null);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const updateDimensions = () => {
      if (containerRef.current) {
        const { width, height } = containerRef.current.getBoundingClientRect();
        setDimensions({ width, height });
      }
    };

    updateDimensions();

    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  useEffect(() => {
    if (!svgRef.current || dimensions.width === 0 || !circles.length) return;

    const svgSelection = d3.select(svgRef.current);
    svgSelection.selectAll('*').remove();

    const aspectRatio = 1.21;
    const { width } = dimensions;
    const height = width * aspectRatio;
    const circleRadius =
      width <= 428 ? Math.min(width, height) / 5 : Math.min(width, height) / 6;
    const scaleFactor = width / 1400;
    const fontSize = Math.max(10, Math.floor(30 * scaleFactor));

    const svg = svgSelection.attr('width', width).attr('height', height);

    const mappedCircles = circles.map((circle, index) => ({
      key: circle.id || `circle-${index}`,
      ...circle,
      ...calculateCirclePosition(index, width, height, circleRadius),
      color: hexToRgba(circle.color),
    }));

    mappedCircles.forEach((circle) => {
      drawCircleWithText(svg, circle, circleRadius, fontSize);
    });
  }, [dimensions, circles]);

  return (
    <div ref={containerRef} className="container">
      <svg
        ref={svgRef}
        className="w-full h-full"
        preserveAspectRatio="xMidYMid meet"
        style={{ margin: '-20% 0 -5% 0' }}
      />
    </div>
  );
};

export default VennDiagramComponent;
