import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { Treemap, ResponsiveContainer } from 'recharts';
import TextBlock from './TextBlock';

const COLORS = [
  '#90EE90', // bright green
  '#4B9CD3', // medium blue
  '#FFE66D', // bright yellow
  '#9B5DE5', // bright purple
  '#45B7D1', // cyan
  '#7DCEA0', // sage green
  '#738ADB', // periwinkle blue
  '#50C878', // emerald green
  '#48D1CC', // turquoise
  '#E6E6FA', // light purple
  '#98FB98', // pale green
  '#87CEEB', // sky blue
  '#DDA0DD', // plum
];

const CustomContent = (props) => {
  const { x, y, width, height, root, name, size, onClick } = props;

  const colorIndex = root.children.findIndex((child) => child.name === name);
  const color = colorIndex >= 0 ? COLORS[colorIndex] : COLORS[0];

  return (
    <g onClick={() => onClick(name)}>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        fill={color}
        style={{
          cursor: 'pointer',
          transition: 'all 0.3s ease',
        }}
      />
      {width > 50 && height > 30 && (
        <text
          x={x + width / 2}
          y={y + height / 2}
          textAnchor="middle"
          fill="#1A1A1A"
          fontSize={14}
          fontWeight="normal"
        >
          {name}
          <tspan x={x + width / 2} dy="1.2em" fontSize={12} fill="#333">
            {size}
          </tspan>
        </text>
      )}
    </g>
  );
};

CustomContent.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  name: PropTypes.string,
  size: PropTypes.number,
  onClick: PropTypes.func,
  root: PropTypes.object,
};

const TreemapChart = ({ container }) => {
  const [selectedTexts, setSelectedTexts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const textContainerRef = useRef(null);

  // Memoize container props to prevent unnecessary re-renders
  const memoizedContainer = useMemo(() => container || [], [container]);

  // Memoize contentData and bubbleTexts
  const contentData = useMemo(
    () => memoizedContainer?.content || {},
    [memoizedContainer]
  );

  const bubbleTexts = useMemo(
    () => memoizedContainer?.bubbleText || [],
    [memoizedContainer]
  );

  // Memoize the data to prevent unnecessary re-renders
  const data = useMemo(
    () => [
      {
        name: 'Categories',
        children: Object.entries(contentData).map(([name, size]) => ({
          name: name.charAt(0).toUpperCase() + name.slice(1).replace('_', ' '),
          size,
        })),
      },
    ],
    [contentData]
  );

  // Use useCallback to memoize the handleClick function
  const handleClick = useCallback(
    (name) => {
      const category = name.toLowerCase().replace(' ', '_');

      const texts = bubbleTexts
        .filter((item) => item.category === category)
        .map((item) => item.text);

      setSelectedTexts(texts);
      setSelectedCategory(name);
    },
    [bubbleTexts]
  );

  // Automatically select first category on mount
  useEffect(() => {
    if (data[0].children.length > 0 && !selectedCategory) {
      const firstCategoryName = data[0].children[0].name;
      handleClick(firstCategoryName);
    }
  }, [data, selectedCategory, handleClick]);

  // Scroll to top whenever selected texts change
  useEffect(() => {
    if (textContainerRef.current) {
      textContainerRef.current.scrollTop = 0;
    }
  }, [selectedTexts]);

  return (
    <>
      <TextBlock caseTitle={container?.heading} cssClass="mt--100 " />
      <div className="container mt-xl-5">
        <div className="row">
          <div className="col-lg-6 p-0 mt-xl-5">
            <div style={{ height: '800px' }}>
              <ResponsiveContainer>
                <Treemap
                  data={data}
                  dataKey="size"
                  stroke="none"
                  content={<CustomContent onClick={handleClick} />}
                />
              </ResponsiveContainer>
            </div>
          </div>
          <div className="col-lg-6 p-0 mt-xl-5">
            <div
              ref={textContainerRef}
              style={{
                height: '800px',
                overflowY: 'auto',
                padding: '16px',
                backgroundColor: '#F0F0F0',
                color: '#1A1A1A',
                borderRadius: '4px',
              }}
            >
              {selectedCategory && (
                <h2
                  style={{
                    marginBottom: '16px',
                    borderBottom: '2px solid #4B9CD3',
                    paddingBottom: '8px',
                    color: '#4B9CD3',
                    textAlign: 'center',
                  }}
                >
                  {selectedCategory}
                </h2>
              )}
              {selectedTexts.length > 0 ? (
                selectedTexts.map((text, index) => (
                  <div key={index} style={{ marginBottom: '16px' }}>
                    <p>{text}</p>
                    {index < selectedTexts.length - 1 && (
                      <hr style={{ margin: '16px 0', borderColor: '#999' }} />
                    )}
                  </div>
                ))
              ) : (
                <p>Click a category to see related texts</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

TreemapChart.propTypes = {
  container: PropTypes.shape({
    content: PropTypes.object,
    bubbleText: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string,
        text: PropTypes.string,
      })
    ),
    heading: PropTypes.string,
  }),
};

export default TreemapChart;
