// TabStyleThree.jsx - Main container component
import React, {
  useEffect,
  useState,
  useCallback,
  useContext,
  useMemo,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { DataContext } from '../../context/DataContext';
import { shuffleArray } from '../../utils/shuffleArray';
import FilterSection from './FilterSection';
import MapView from './MapView';
import PaginationControls from './PaginationControls';
import ArchiveItemsList from './ArchiveItemsList';
import MapToggle from './MapToggle';
import { useFilters } from '../../hooks/useFilters';
import { usePagination } from '../../hooks/usePagination';

const TabStyleThree = ({ column, documents, videos }) => {
  const paginationRef = useRef(null);

  // Convert data to consistent format with type labels
  const labeledDocuments = useMemo(
    () => documents.map((doc) => ({ ...doc, type: 'document' })),
    [documents]
  );

  const labeledVideos = useMemo(
    () => videos.map((video) => ({ ...video, type: 'video' })),
    [videos]
  );

  // State for filtered and shuffled data
  const [tabOne, setTabOne] = useState([]);
  const [tabTwo, setTabTwo] = useState([]);

  // Map related state
  const [showMap, setShowMap] = useState(
    JSON.parse(sessionStorage.getItem('showMap') || 'false')
  );
  const [selectedMarker, setSelectedMarker] = useState(null);

  // Context for data sharing
  const { setCollectionNameAndItemId } = useContext(DataContext);

  // Custom hooks for filters and pagination
  const {
    filters,
    setFilters,
    filteredStates,
    filterItems,
    updateFilteredStates,
    loadSessionData,
    saveSessionData,
  } = useFilters(labeledDocuments, labeledVideos);

  const {
    currentPage,
    setCurrentPage,
    itemsPerPage,
    totalPages,
    paginatedTabOne,
    paginatedTabTwo,
    handlePageChange,
    handleItemsPerPageChange,
  } = usePagination(tabOne, tabTwo);

  // Updated scrolling function
  const scrollToTop = useCallback(() => {
    const tablistInner = document.querySelector('#map-view-toggle');
    if (tablistInner) {
      const offset = 50; // Adjust this value as needed
      const topPosition =
        tablistInner.getBoundingClientRect().top + window.pageYOffset - offset;

      window.scrollTo({
        top: topPosition,
        behavior: 'instant',
      });
    }
  }, []);

  // Initialize component
  useEffect(() => {
    loadSessionData();
    setTabOne(shuffleArray(labeledVideos));
    setTabTwo(shuffleArray(labeledDocuments));
    updateFilteredStates([...labeledDocuments, ...labeledVideos]);

    // Load selected marker from session if available
    const storedMarker = sessionStorage.getItem('selectedMarker');
    if (storedMarker) {
      setSelectedMarker(JSON.parse(storedMarker));
    }
  }, [labeledDocuments, labeledVideos, loadSessionData, updateFilteredStates]);

  // Save session data when filters or pagination changes
  useEffect(() => {
    saveSessionData();
    const filteredItems = filterItems([...labeledDocuments, ...labeledVideos]);
    updateFilteredStates(filteredItems);
  }, [
    filters,
    labeledDocuments,
    labeledVideos,
    saveSessionData,
    filterItems,
    updateFilteredStates,
    currentPage,
    itemsPerPage,
  ]);

  // Update filtered content when filters change
  useEffect(() => {
    const filteredVideos = filterItems(labeledVideos);
    const filteredDocuments = filterItems(labeledDocuments);
    setTabOne(filteredVideos);
    setTabTwo(filteredDocuments);

    // Reset to first page when filters change
    setCurrentPage(1);
  }, [filters, labeledDocuments, labeledVideos, filterItems, setCurrentPage]);

  useEffect(() => {
    const timer = setTimeout(scrollToTop, 100);
    return () => clearTimeout(timer);
  }, [currentPage, scrollToTop]);

  // Update map visibility in session storage
  useEffect(() => {
    sessionStorage.setItem('showMap', JSON.stringify(showMap));
  }, [showMap]);

  // Clear selected marker when map is hidden
  useEffect(() => {
    if (!showMap) {
      setSelectedMarker(null);
      sessionStorage.removeItem('selectedMarker');
    }
  }, [showMap]);

  // Handle marker selection
  const handleMarkerSelect = useCallback((marker) => {
    setSelectedMarker(marker);
    sessionStorage.setItem('selectedMarker', JSON.stringify(marker));
  }, []);

  // Item click handler for navigation
  const handleClick = useCallback(
    (item) => {
      setCollectionNameAndItemId(item.type, item.id);
    },
    [setCollectionNameAndItemId]
  );

  // Keyboard navigation handler
  const handleKeyDown = useCallback(
    (item, event) => {
      if (!event) {
        console.error('Event is undefined');
        return;
      }

      if (event.key === 'Enter') {
        setCollectionNameAndItemId(item.type, item.id);
      }
    },
    [setCollectionNameAndItemId]
  );

  // Memoize the combined items for filtering
  const allItems = useMemo(
    () => [...labeledDocuments, ...labeledVideos],
    [labeledDocuments, labeledVideos]
  );

  return (
    <div>
      {/* Filter Section */}
      <FilterSection
        filteredStates={filteredStates}
        filters={filters}
        setFilters={setFilters}
      />

      {/* Map Toggle */}
      <MapToggle showMap={showMap} setShowMap={setShowMap} />

      {showMap ? (
        <MapView
          items={filterItems(allItems)}
          selectedMarker={selectedMarker}
          onMarkerSelect={handleMarkerSelect}
          onItemClick={handleClick}
          onKeyDown={handleKeyDown}
        />
      ) : (
        <>
          <Tabs>
            <div className="row text-center d-none">
              <div className="col-lg-12">
                <div className="tablist-inner">
                  <TabList className="pv-tab-button text-center mt--0">
                    <Tab>
                      <span>All</span>
                    </Tab>
                  </TabList>
                </div>
              </div>
            </div>
            <TabPanel className="row row--35">
              <ArchiveItemsList
                videoItems={paginatedTabOne}
                documentItems={paginatedTabTwo}
                column={column}
                handleClick={handleClick}
                handleKeyDown={handleKeyDown}
              />
            </TabPanel>
          </Tabs>

          {/* Pagination Controls */}
          {(tabOne.length > 0 || tabTwo.length > 0) && totalPages > 1 && (
            <div ref={paginationRef} className="pagination-wrapper mt-xl-5">
              <PaginationControls
                currentPage={currentPage}
                totalPages={totalPages}
                itemsPerPage={itemsPerPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            </div>
          )}
        </>
      )}
    </div>
  );
};

TabStyleThree.propTypes = {
  column: PropTypes.string,
  documents: PropTypes.array.isRequired,
  videos: PropTypes.array.isRequired,
};

export default TabStyleThree;
