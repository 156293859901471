// MapToggle.jsx
import React from 'react';
import PropTypes from 'prop-types';
import Switch from 'react-switch';

const MapToggle = ({ showMap, setShowMap }) => {
  // Use a unique ID for accessibility
  const toggleId = 'map-view-toggle';

  return (
    <div className="switch-container text-center mt-xl-5 mb-xl-5 d-flex justify-content-center align-items-center">
      {/* Hidden actual input for accessibility */}
      <input
        type="checkbox"
        id={toggleId}
        checked={showMap}
        onChange={() => setShowMap((prev) => !prev)}
        className="sr-only"
        aria-labelledby="map-toggle-label"
      />

      {/* Visual label */}
      <span
        id="map-toggle-label"
        className="mr-2 mb--30"
        style={{ marginBottom: 0 }}
      >
        Map
      </span>

      {/* Visual toggle (not the actual control) */}
      <Switch
        checked={showMap}
        onChange={() => setShowMap((prev) => !prev)}
        checkedIcon={false}
        uncheckedIcon={false}
        aria-hidden="true" // Hide from screen readers as we have a proper input above
      />
    </div>
  );
};

MapToggle.propTypes = {
  showMap: PropTypes.bool.isRequired,
  setShowMap: PropTypes.func.isRequired,
};

export default React.memo(MapToggle);
