// hooks/useFilters.js
import { useState, useCallback } from 'react';
// Removed useEffect since it's not being used

// Export as named export to match the import pattern in TabStyleThree
export function useFilters() {
  // Removed unused parameters (documents, videos)
  const [filters, setFilters] = useState({
    selectedAuthors: [],
    selectedDocTypes: [],
    selectedCategories: [],
    selectedLocations: [],
    selectedShowcases: [],
    selectedDublinCoreTypes: [],
    selectedKeywords: [],
  });

  const [filteredStates, setFilteredStates] = useState({
    authors: [],
    docTypes: [],
    categories: [],
    locations: [],
    showcases: [],
    dublinCoreTypes: [],
    keywords: [],
  });

  // Utility function to extract unique values from items
  const extractUniqueValues = useCallback((items, keyPath, isArray = false) => {
    const values = items.flatMap((item) => {
      const value = keyPath.reduce(
        (acc, key) => acc?.[key] ?? null,
        item?.attributes?.contentType
      );
      if (isArray && Array.isArray(value)) {
        return value.map((v) => v?.attributes?.name?.trim());
      }
      return value ? [value.trim()] : [];
    });

    return [...new Set(values.filter(Boolean))].sort((a, b) =>
      a
        .replace(/[.,]/g, '')
        .toLowerCase()
        .localeCompare(b.replace(/[.,]/g, '').toLowerCase(), undefined, {
          sensitivity: 'base',
        })
    );
  }, []);

  // Load filters from session storage
  const loadSessionData = useCallback(() => {
    const storedFilters = [
      'Authors',
      'DocTypes',
      'Categories',
      'Locations',
      'Showcases',
      'DublinCoreTypes',
      'Keywords',
    ].reduce(
      (acc, key) => ({
        ...acc,
        [`selected${key}`]:
          JSON.parse(sessionStorage.getItem(`selected${key}`)) || [],
      }),
      {}
    );
    setFilters((prev) => ({ ...prev, ...storedFilters }));
  }, []);

  // Save filters to session storage
  const saveSessionData = useCallback(() => {
    Object.entries(filters).forEach(([key, value]) => {
      sessionStorage.setItem(key, JSON.stringify(value));
    });
  }, [filters]);

  // Filter items based on current filter selections
  const filterItems = useCallback(
    (items) =>
      items.filter((item) => {
        const content = item?.attributes?.contentType;
        const keywords =
          content?.keywords?.data?.map((kw) => kw?.attributes?.name?.trim()) ||
          [];

        return (
          (!filters.selectedAuthors.length ||
            filters.selectedAuthors.includes(content?.author?.trim())) &&
          (!filters.selectedDocTypes.length ||
            filters.selectedDocTypes.includes(
              content?.doc_type?.data?.attributes?.name?.trim()
            )) &&
          (!filters.selectedCategories.length ||
            filters.selectedCategories.includes(
              content?.category?.data?.attributes?.name?.trim()
            )) &&
          (!filters.selectedLocations.length ||
            filters.selectedLocations.includes(content?.location?.trim())) &&
          (!filters.selectedShowcases.length ||
            filters.selectedShowcases.includes(
              content?.showcase?.data?.attributes?.name?.trim()
            )) &&
          (!filters.selectedDublinCoreTypes.length ||
            filters.selectedDublinCoreTypes.includes(
              content?.dublin_core_type?.data?.attributes?.name?.trim()
            )) &&
          (!filters.selectedKeywords.length ||
            filters.selectedKeywords.every((kw) =>
              keywords.includes(kw.trim())
            ))
        );
      }),
    [filters]
  );

  // Update available filter options based on filtered items
  const updateFilteredStates = useCallback(
    (items) => {
      setFilteredStates({
        authors: extractUniqueValues(items, ['author']),
        docTypes: extractUniqueValues(items, [
          'doc_type',
          'data',
          'attributes',
          'name',
        ]),
        categories: extractUniqueValues(items, [
          'category',
          'data',
          'attributes',
          'name',
        ]),
        locations: extractUniqueValues(items, ['location']),
        showcases: extractUniqueValues(items, [
          'showcase',
          'data',
          'attributes',
          'name',
        ]),
        dublinCoreTypes: extractUniqueValues(items, [
          'dublin_core_type',
          'data',
          'attributes',
          'name',
        ]),
        keywords: extractUniqueValues(items, ['keywords', 'data'], true),
      });
    },
    [extractUniqueValues]
  );

  return {
    filters,
    setFilters,
    filteredStates,
    filterItems,
    updateFilteredStates,
    loadSessionData,
    saveSessionData,
  };
}

// Also provide a default export for backward compatibility
export default useFilters;
