// ArchiveItemsList.jsx
import React from 'react';
import PropTypes from 'prop-types';
import ArchiveElement from '../objects/ArchiveElement';

const ArchiveItemsList = ({ videoItems, documentItems, column }) => {
  if (videoItems.length === 0 && documentItems.length === 0) {
    return (
      <div className="col-12 text-center mt-5">
        <p>No results found based on the selected filters.</p>
      </div>
    );
  }

  return (
    <>
      {videoItems.length > 0 &&
        videoItems.map((item, index) => (
          <ArchiveElement
            key={`video-${index}`}
            column={column}
            item={item}
            type="video"
          />
        ))}
      {documentItems.length > 0 &&
        documentItems.map((item, index) => (
          <ArchiveElement
            key={`doc-${index}`}
            column={column}
            item={item}
            type="document"
          />
        ))}
    </>
  );
};

ArchiveItemsList.propTypes = {
  videoItems: PropTypes.array.isRequired,
  documentItems: PropTypes.array.isRequired,
  column: PropTypes.string,
};

export default React.memo(ArchiveItemsList);
