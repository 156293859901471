// PaginationControls.jsx
import React from 'react';
import PropTypes from 'prop-types';

const PaginationControls = ({
  currentPage,
  totalPages,
  itemsPerPage,
  onPageChange,
  onItemsPerPageChange,
}) => {
  const renderPageButtons = () => {
    const pages = [];
    // Always show first page
    pages.push(
      <button
        key="first"
        type="button"
        className={`pagination-button ${currentPage === 1 ? 'active' : ''}`}
        onClick={() => onPageChange(1)}
      >
        1
      </button>
    );

    // Show ellipsis if needed
    if (currentPage > 3) {
      pages.push(
        <span key="ellipsis1" className="pagination-ellipsis">
          ...
        </span>
      );
    }

    // Show current page and surrounding pages
    for (
      let i = Math.max(2, currentPage - 1);
      i <= Math.min(totalPages - 1, currentPage + 1);
      i += 1
    ) {
      if (i !== 1 && i !== totalPages) {
        // Don't show first and last pages here, they are added separately
        pages.push(
          <button
            key={i}
            type="button"
            className={`pagination-button ${currentPage === i ? 'active' : ''}`}
            onClick={() => onPageChange(i)}
          >
            {i}
          </button>
        );
      }
    }

    // Show ellipsis if needed
    if (currentPage < totalPages - 2) {
      pages.push(
        <span key="ellipsis2" className="pagination-ellipsis">
          ...
        </span>
      );
    }

    // Always show last page if there is more than one page
    if (totalPages > 1) {
      pages.push(
        <button
          key="last"
          type="button"
          className={`pagination-button ${
            currentPage === totalPages ? 'active' : ''
          }`}
          onClick={() => onPageChange(totalPages)}
        >
          {totalPages}
        </button>
      );
    }

    return pages;
  };

  return (
    <div className="pagination-container">
      <div className="empty-space-left" />
      <div className="pagination-navigation">
        <button
          type="button"
          className="pagination-button prev"
          onClick={() => onPageChange(currentPage - 1)}
          disabled={currentPage === 1}
          aria-label="Previous page"
        >
          <span className="pagination-arrow">←</span>
        </button>

        {renderPageButtons()}

        <button
          type="button"
          className="pagination-button next"
          onClick={() => onPageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          aria-label="Next page"
        >
          <span className="pagination-arrow">→</span>
        </button>
      </div>

      <div className="items-per-page">
        <label htmlFor="items-per-page">
          Items per page:
          <select
            id="items-per-page"
            value={itemsPerPage}
            onChange={onItemsPerPageChange}
          >
            <option value="9">9</option>
            <option value="18">18</option>
            <option value="27">27</option>
            <option value="36">36</option>
          </select>
        </label>
      </div>
    </div>
  );
};

PaginationControls.propTypes = {
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  itemsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  onItemsPerPageChange: PropTypes.func.isRequired,
};

export default React.memo(PaginationControls);
