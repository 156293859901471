// FilterSection.jsx
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

const FilterSection = ({ filteredStates, filters, setFilters }) => {
  const renderSelect = (label, stateKey, id) => (
    <div className="filter-item mt-xl-4">
      <label htmlFor={id} className="mr-2 section-title">
        <span className="subtitle">{label}</span>
      </label>
      <div className="select__container">
        <Select
          id={id}
          isMulti
          options={filteredStates[stateKey].map((value) => ({
            value,
            label: value,
          }))}
          value={filters[
            `selected${stateKey.charAt(0).toUpperCase()}${stateKey.slice(1)}`
          ].map((value) => ({ value, label: value }))}
          onChange={(selectedOptions) =>
            setFilters((prev) => ({
              ...prev,
              [`selected${stateKey.charAt(0).toUpperCase()}${stateKey.slice(
                1
              )}`]: selectedOptions.map((s) => s.value),
            }))
          }
          className="basic-multi-select"
          classNamePrefix="select"
        />
      </div>
    </div>
  );

  return (
    <>
      <div className="col-lg-12 text-center">
        <div className="row">
          <div className="col-lg-12">
            <div className="section-title mb--30 text-center">
              <h3 className="title mt--20 mb--50">Search by:</h3>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 d-flex flex-wrap justify-content-center filter-container mb--30">
        {[
          { key: 'authors', label: 'Authors' },
          { key: 'docTypes', label: 'Document Types' },
          { key: 'categories', label: 'Categories' },
          { key: 'locations', label: 'Locations' },
          { key: 'showcases', label: 'Cases' },
          { key: 'dublinCoreTypes', label: 'Dublin Core Types' },
          { key: 'keywords', label: 'Keywords' },
        ].map(({ key, label }) => (
          <div key={key}>{renderSelect(label, key, `${key}-filter`)}</div>
        ))}
      </div>
    </>
  );
};

FilterSection.propTypes = {
  filteredStates: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  setFilters: PropTypes.func.isRequired,
};

export default React.memo(FilterSection);
