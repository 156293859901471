// hooks/usePagination.js
import { useState, useEffect } from 'react';

// Make sure to export the hook directly, not as default
export function usePagination(tabOne, tabTwo) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(9); // Default items per page
  const [totalPages, setTotalPages] = useState(1);
  const [paginatedTabOne, setPaginatedTabOne] = useState([]);
  const [paginatedTabTwo, setPaginatedTabTwo] = useState([]);

  // Initialize from session storage if available
  useEffect(() => {
    const storedPage = sessionStorage.getItem('currentPage');
    const storedItemsPerPage = sessionStorage.getItem('itemsPerPage');

    if (storedPage) setCurrentPage(parseInt(storedPage, 10));
    if (storedItemsPerPage) setItemsPerPage(parseInt(storedItemsPerPage, 10));
  }, []);

  // Calculate pagination whenever tabs or pagination settings change
  useEffect(() => {
    const totalItems = tabOne.length + tabTwo.length;
    setTotalPages(Math.ceil(totalItems / itemsPerPage));

    // Get start and end indices for current page
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;

    // We need to handle pagination across both arrays (tabOne and tabTwo)
    if (startIndex < tabOne.length) {
      // If start index is within tabOne
      const tabOneEndIndex = Math.min(endIndex, tabOne.length);
      setPaginatedTabOne(tabOne.slice(startIndex, tabOneEndIndex));

      // If we have any slots left, fill with tabTwo items
      const remainingSlots = itemsPerPage - (tabOneEndIndex - startIndex);
      if (remainingSlots > 0) {
        setPaginatedTabTwo(tabTwo.slice(0, remainingSlots));
      } else {
        setPaginatedTabTwo([]);
      }
    } else {
      // If start index is beyond tabOne
      const tabTwoStartIndex = startIndex - tabOne.length;
      const tabTwoEndIndex = Math.min(
        tabTwoStartIndex + itemsPerPage,
        tabTwo.length
      );
      setPaginatedTabOne([]);
      setPaginatedTabTwo(tabTwo.slice(tabTwoStartIndex, tabTwoEndIndex));
    }
  }, [tabOne, tabTwo, currentPage, itemsPerPage]);

  // Page change handler
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
      sessionStorage.setItem('currentPage', page.toString());
    }
  };

  // Items per page change handler
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = parseInt(event.target.value, 10);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page
    sessionStorage.setItem('itemsPerPage', newItemsPerPage.toString());
  };

  return {
    currentPage,
    setCurrentPage,
    itemsPerPage,
    setItemsPerPage,
    totalPages,
    paginatedTabOne,
    paginatedTabTwo,
    handlePageChange,
    handleItemsPerPageChange,
  };
}

// Also provide a default export for backward compatibility
export default usePagination;
