// MapView.jsx
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import { Link } from 'react-router-dom';
import slugify from 'slugify';
import { accessToken, siteUrl } from '../../utils/constants';

const MapView = ({
  items,
  selectedMarker,
  onMarkerSelect,
  onItemClick,
  onKeyDown,
}) => {
  const [viewport, setViewport] = useState({
    zoom: 1.6,
  });

  const handleMarkerClick = useCallback(
    (item) => {
      onMarkerSelect(item);
    },
    [onMarkerSelect]
  );

  return (
    <div
      className="mt-xl-5"
      style={{ width: '100%', height: '800px', border: '0.5px solid #ccc' }}
    >
      <ReactMapGL
        latitude={viewport.latitude}
        longitude={viewport.longitude}
        zoom={viewport.zoom}
        mapboxApiAccessToken={accessToken}
        mapStyle="mapbox://styles/mapbox/outdoors-v11"
        onViewportChange={(viewportData) => setViewport(viewportData)}
        scrollZoom={false}
        touchZoom={false}
        touchRotate={false}
        keyboard={false}
        doubleClickZoom={false}
        width="100%"
        height="100%"
      >
        <div style={{ position: 'absolute', top: 10, left: 10 }}>
          <NavigationControl />
        </div>
        {items.map((item, index) => {
          const latitude = item?.attributes?.contentType?.latitude;
          const longitude = item?.attributes?.contentType?.longitude;

          if (latitude && longitude) {
            return (
              <Marker key={index} latitude={latitude} longitude={longitude}>
                <button
                  type="button"
                  onClick={() => handleMarkerClick(item)}
                  style={{
                    background: 'none',
                    border: 'none',
                    padding: 0,
                    cursor: 'pointer',
                  }}
                  aria-label="Select marker"
                >
                  <img
                    src="https://docs.mapbox.com/mapbox-gl-js/assets/custom_marker.png"
                    alt="marker"
                    style={{
                      width: '20px',
                      height: '20px',
                      filter:
                        selectedMarker?.id === item.id
                          ? 'hue-rotate(180deg)'
                          : 'none',
                    }}
                  />
                </button>
              </Marker>
            );
          }

          return null;
        })}
        {selectedMarker && (
          <MarkerDetail
            selectedMarker={selectedMarker}
            onClose={() => onMarkerSelect(null)}
            onItemClick={onItemClick}
            onKeyDown={onKeyDown}
          />
        )}
      </ReactMapGL>
    </div>
  );
};

// MarkerDetail component (part of MapView)
const MarkerDetail = ({ selectedMarker, onClose, onItemClick, onKeyDown }) => (
  <div className="side-window sidebar">
    <div className="p-4">
      <button type="button" onClick={onClose} className="close-button">
        &times;
      </button>
      <h4 className="mt-xl-5 mb-xl-4">
        <Link
          to={`archive/${
            selectedMarker?.attributes?.contentType?.title
              ? slugify(selectedMarker.attributes.contentType.title, {
                  lower: true,
                  strict: true,
                  trim: true,
                })
              : ''
          }`}
          className="no-hover"
          onClick={() => onItemClick(selectedMarker)}
          onKeyDown={(event) => onKeyDown(selectedMarker, event)}
        >
          {selectedMarker.attributes.contentType.title || ''}
        </Link>
      </h4>
      {selectedMarker.attributes.contentType.image?.data?.attributes?.url && (
        <div>
          <Link
            to={`archive/${
              selectedMarker?.attributes?.contentType?.title
                ? slugify(selectedMarker.attributes.contentType.title, {
                    lower: true,
                    strict: true,
                    trim: true,
                  })
                : ''
            }`}
            onClick={() => onItemClick(selectedMarker)}
            onKeyDown={(event) => onKeyDown(selectedMarker, event)}
          >
            <img
              src={`${siteUrl}${selectedMarker.attributes.contentType.image.data.attributes.url}`}
              alt="Archive item"
              className="w-100"
            />
          </Link>
        </div>
      )}
      <div className="mt-xl-4">
        <p>{selectedMarker.attributes.contentType.description}</p>
        <p>
          <strong>Category:</strong>{' '}
          {
            selectedMarker.attributes.contentType.category?.data?.attributes
              ?.name
          }
        </p>
        <p>
          <strong>Author:</strong>{' '}
          {selectedMarker.attributes.contentType.author}
        </p>
      </div>
    </div>
  </div>
);

MapView.propTypes = {
  items: PropTypes.array.isRequired,
  selectedMarker: PropTypes.object,
  onMarkerSelect: PropTypes.func.isRequired,
  onItemClick: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
};

export default React.memo(MapView);
