import React, {
  useContext,
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly } from 'react-device-detect';
import { FiChevronUp } from 'react-icons/fi';
import ScrollToTop from 'react-scroll-up';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';
import slugify from 'slugify';
import Gallery from 'react-photo-gallery';

// Common components
import PageHelmet from './common/Helmet';
import Header from './common/Header';
import BreadcrumbCases from './common/BreadcrumbCases';
import ChapterButtons from './common/ChapterButtons';
import FooterTwo from './common/FooterTwo';

// Object components
import MapTwo from './objects/MapTwo';
import TreemapChart from './objects/TreemapChart';
import VideoModal from './objects/VideoModal';
import VennDiagramComponent from './objects/VennDiagram';
import TextBlock from './objects/TextBlock';
import Video from './objects/Video';
import Image from './objects/Image';
import Images from './objects/Images';
import TextBlockTwo from './objects/TextBlockTwo';
import AudioFile from './objects/AudioFile';

// Context and utilities
import { DataContext } from '../context/DataContext';
import { siteUrl, chapterNames } from '../utils/constants';
import { parseStorageItem, setStorageItem } from '../utils/funcs';

const CaseDetails = ({ setRedirectToCases }) => {
  const { videosVimeo, cases, videos } = useContext(DataContext);
  const casePath = window?.location?.pathname?.replace('/cases/', '');
  const [activeContainer, setActiveContainer] = useState(0);
  const [caseName, setCaseName] = useState(parseStorageItem('caseName') || {});
  const [data, setData] = useState(parseStorageItem('data') || []);
  const [toTop, setToTop] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectedVideoUrl, setSelectedVideoUrl] = useState(null);
  const [selectedVideoDescription, setSelectedVideoDescription] =
    useState(null);

  // Process case data when cases are loaded
  useEffect(() => {
    setActiveContainer(0);
    if (!cases || !Array.isArray(cases) || cases.length === 0) return;

    const caseData = cases.find(
      (item) =>
        item.attributes.name &&
        slugify(item.attributes.name.toLowerCase()) === casePath
    );

    if (caseData) {
      setCaseName(caseData);
      setStorageItem('caseName', caseData);

      const containerData = caseData.attributes || [];
      setData(containerData);
      setStorageItem('data', containerData);

      // Process map data if available
      const mapDataArray = caseData?.attributes?.map;
      if (mapDataArray?.length) {
        setStorageItem('caseMapData', mapDataArray);

        const userChapters = mapDataArray.map(
          ({ pitch, longitude, latitude, zoom }) => ({
            pitch,
            duration: 6000,
            center: [longitude, latitude],
            zoom,
          })
        );

        setStorageItem('caseMapChapters', userChapters);
      }
    } else {
      setRedirectToCases(true);
    }
  }, [cases, casePath, setRedirectToCases]);

  // Callback for activating a container
  const activateContainer = useCallback((containerId) => {
    setActiveContainer(containerId);
    sessionStorage.setItem('activeChapter', containerId);
  }, []);

  // Determine background image URL based on device
  const imgUrl = useMemo(() => {
    if (!caseName?.attributes?.backgroundImage?.data?.attributes) return '';

    const imageAttributes = caseName.attributes.backgroundImage.data.attributes;

    if (isMobileOnly && imageAttributes.formats?.small?.url) {
      return imageAttributes.formats.small.url;
    }

    return imageAttributes.url || '';
  }, [caseName]);

  // Get current container
  const container = useMemo(
    () => data[chapterNames[activeContainer]],
    [data, activeContainer]
  );

  // Determine CSS classes based on container type
  const containerCssClasses = useMemo(() => {
    const isMap = container?.[0]?.__component === 'element.map';
    const isD3js = container?.__component === 'element.d3js';

    const baseClass = isMap
      ? 'pt--20 pb--70'
      : 'rn-blog-details pt--20 pb--70 bg_color--1';

    const darkClass = isD3js ? '' : '';

    return { baseClass, darkClass };
  }, [container]);

  // Create Vimeo image mapping
  const vimeoImageMap = useMemo(() => {
    const imageIndex = isMobileOnly ? 4 : 5;
    const imageMap = {};

    videosVimeo.forEach((video) => {
      const videoId = video?.uri?.replace('/videos/', '');
      imageMap[videoId] = video.pictures.sizes[imageIndex].link;
    });

    return imageMap;
  }, [videosVimeo]); // Removed isMobileOnly dependency as it's not needed

  // Filter videos related to current case
  const filteredVideos = useMemo(() => {
    if (!videos || !caseName?.attributes?.name) return [];

    return videos.filter((video) => {
      const showcaseName =
        video?.attributes?.contentType?.showcase?.data?.attributes?.name;
      return (
        showcaseName?.toLowerCase() === caseName.attributes.name.toLowerCase()
      );
    });
  }, [videos, caseName]);

  // Process videos into photo gallery format
  const photos = useMemo(
    () =>
      filteredVideos
        .map((video) => {
          const imageData =
            video?.attributes?.contentType?.image?.data?.attributes;

          if (!imageData) return null;

          return {
            key: video.id.toString(),
            description: video?.attributes?.contentType?.description,
            height: imageData.height,
            src: `${siteUrl}${imageData.url}`,
            url: video.attributes.contentType.url,
            width: imageData.width,
          };
        })
        .filter(Boolean),
    [filteredVideos]
  );

  // Modal handlers
  const handleImageClick = useCallback((event, { photo }) => {
    setSelectedImage(photo.src);
    setSelectedVideoUrl(photo.url);
    setSelectedVideoDescription(photo.description);
    setIsModalOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setSelectedVideoDescription(null);
  }, []);

  // Render different component types
  const renderComponent = useCallback(
    (item, index) => {
      if (!item) return null;

      let vidImg;
      let audioFileUrl;

      switch (item.__component) {
        case 'element.heading':
          return item?.heading ? (
            <TextBlock
              key={index}
              caseTitle={item.heading}
              cssClass="mt--100"
            />
          ) : null;

        case 'element.video':
          // Declare variable before the case to avoid lexical declaration error
          vidImg =
            item.url?.indexOf('https://vimeo.com/') !== -1
              ? vimeoImageMap[item?.url?.replace('https://vimeo.com/', '')]
              : '';
          return (
            <Video
              key={index}
              type={item.type ?? ''}
              videoData={item}
              vidImg={vidImg}
            />
          );

        case 'element.image':
          return item?.image?.data?.attributes ? (
            <Image
              key={index}
              type={item.type ?? ''}
              url={siteUrl + item.image.data.attributes.url}
              caption={item.caption || ''}
            />
          ) : null;

        case 'element.image-collection':
          return item?.image?.data ? (
            <Images
              key={index}
              type={item.type}
              images={item.image.data}
              toTop
            />
          ) : null;

        case 'element.text-block':
          return item?.textBlock ? (
            <TextBlockTwo key={index} content={item.textBlock} />
          ) : null;

        case 'element.preamble':
          return item.preamble ? (
            <div key={index} className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-wrapper">
                    <div className="inner">
                      <blockquote className="rn-blog-quote">
                        <BlocksRenderer content={item.preamble} />
                      </blockquote>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null;

        case 'element.audio-file':
          // Declare variable before the case to avoid lexical declaration error
          audioFileUrl = item?.audioFile?.data?.attributes?.url
            ? `${siteUrl}${item.audioFile.data.attributes.url}`
            : null;

          return audioFileUrl ? (
            <div key={index} className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="inner-wrapper">
                    <div className="inner mt-xl-3 mb-xl-5">
                      <AudioFile url={audioFileUrl} />
                      <p className="text-center mt-3">
                        {item?.audioFile?.data?.attributes?.caption}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null;

        case 'element.video-gallery':
          return (
            <div key={index}>
              <Gallery photos={photos} onClick={handleImageClick} />
            </div>
          );

        case 'element.circles':
          return item?.circles ? (
            <VennDiagramComponent key={index} circles={item.circles} />
          ) : null;

        case 'element.d3js':
          return <TreemapChart key={index} container={item} />;

        default:
          return null;
      }
    },
    [vimeoImageMap, photos, handleImageClick]
  );

  // Handle scroll to top
  const handleScrollToTop = useCallback(() => setToTop(1), []);

  return (
    <>
      <PageHelmet pageTitle="Case Details" />
      <Header stickyNav={false} />

      <BreadcrumbCases
        bgCaseImg={siteUrl + imgUrl}
        subHeading={caseName?.attributes?.subHeading}
        caseName={caseName?.attributes?.name}
        dataOverlay={5}
        toTop={toTop}
        setToTop={setToTop}
      />

      <div
        id="chapters"
        className={`${containerCssClasses.baseClass} ${containerCssClasses.darkClass}`}
      >
        <ChapterButtons
          activeContainer={activeContainer}
          activateContainer={activateContainer}
          caseName={caseName}
        />

        {container && container.map(renderComponent)}

        {container && container?.[0]?.__component === 'element.map' && (
          <MapTwo mapDataArray={container[0].map} section={caseName.caseName} />
        )}

        <ChapterButtons
          activeContainer={activeContainer}
          activateContainer={activateContainer}
          caseName={caseName}
        />
      </div>

      {selectedVideoUrl && (
        <VideoModal
          show={isModalOpen}
          onClose={closeModal}
          imageSrc={selectedImage}
          url={selectedVideoUrl}
          description={selectedVideoDescription}
        />
      )}

      <div
        className="backto-top"
        onClick={handleScrollToTop}
        onKeyDown={handleScrollToTop}
        role="button"
        tabIndex={0}
        aria-label="Button to top"
      >
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>

      <FooterTwo setToTop={setToTop} toTop={toTop} />
    </>
  );
};

CaseDetails.propTypes = {
  setRedirectToCases: PropTypes.func.isRequired,
};

export default CaseDetails;
