import React from 'react';
import PropTypes from 'prop-types';

const DataItem = ({ heading, text = '', resources = null }) => {
  if (
    heading === 'Resources' &&
    Array.isArray(resources) &&
    resources.length === 0
  ) {
    return '';
  }

  if (!text && (!resources || resources.length === 0)) {
    return '';
  }

  const renderResources = () => {
    if (resources && resources.length > 0) {
      return (
        <div>
          {resources.map((resource, index) => (
            <div key={resource.id}>
              <a
                className="theme-gradient"
                href={resource.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                External link {index + 1}
              </a>
            </div>
          ))}
        </div>
      );
    }
    return null;
  };

  const renderText = () => {
    if (text) {
      return <span>{text}</span>;
    }
    return null;
  };

  return (
    <div className="port-view mt-xl-3 mb-xl-3">
      <h5>{heading}</h5>
      {resources && resources.length > 0 ? renderResources() : renderText()}
    </div>
  );
};

DataItem.propTypes = {
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  resources: PropTypes.array,
};

export default DataItem;
